export const required = (value) => {
  return !!value;
};

export const matches = (value, match) => {
  return value === match;
};

export const min = (value, length) => {
  return value.length >= length;
};

export const email = (value) => {
  if (!value) {
    return true;
  }

  if (typeof value !== 'string') {
    return false;
  }

  // A simplified version pulled from https://www.w3resource.com/javascript/form/email-validation.php
  const regex = new RegExp('^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$');

  return value.match(regex) ? true : false;
};

export const subdomain = (value) => {
  const regex = new RegExp('^[a-zA-Z]+[a-zA-Z0-9\\-]*$');

  return value.match(regex) ? true : false;
};

export const hasUppercase = (value) => {
  const regex = new RegExp(/[A-Z]/g);
  return regex.test(value);
};

export const hasLowercase = (value) => {
  const regex = new RegExp(/[a-z]/g);
  return regex.test(value);
};

export const hasNumber = (value) => {
  const regex = new RegExp(/[0-9]/g);
  return regex.test(value);
};

export const hasSymbol = (value) => {
  const regex = new RegExp(/[ `!@#$%^&*()_+\-={};':"|,.<>?~]/);
  return regex.test(value);
};