var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("button", {
    staticClass: "hidden-submit",
    attrs: { type: "submit" }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }