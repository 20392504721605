<!-- Use this to enable pressing the "Enter" keys to function as pressing "Done" in a slideout editor -->
<template>
  <button type="submit" class="hidden-submit" />
</template>

<script>
export default {
  name: 'HiddenSubmitButton',
};
</script>

<style scoped>
.hidden-submit {
  display: none;
}
</style>
